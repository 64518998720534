<template>
  <section class="what-hero">
    <v-img width="100%" :contain="$vuetify.breakpoint.mdAndUp" 
    :src="require('../../assets/images/banner-1.png')">
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="12" lg="9" sm="11">
          <v-row class="what-hero">
            <v-col cols="12" md="6" sm="4">
              <div class="what-hero__title">
                What is <span class="what-hero__title" style="color: #0F75BC"> Minimally 
                Invasive Surgery </span>Hybrid 
                Online Learning?
              </div>
            </v-col>
            <v-col cols="12" md="6" sm="8">
              <div class="what-hero__desc"
              :class="$vuetify.breakpoint.smAndDown ? 'mx-5':''">
                Since the introduction of minimally invasive surgery (MIS) in the early 1990s, it has gained wide spread acceptance because of the improved outcomes, such as faster recovery, shorter hospitalization, and better quality of life, that resulted from the procedure.
                <br>
                <br>
                The Philippine Society of General Surgeons (PSGS) has incorporated MIS into its Surgical Residency curriculum to improve knowledge in and facilitate learning of MIS. The PSGS Subcommittee on MIS has developed a standardized curriculum that will be delivered through an innovative approach with the goal of enhancing and improving the training of basic laparoscopy. This novel program is called Hybrid Online Learning (HOL), a blended-learning solution that combines the online activities plus a live, virtual face-to-face (FTF) workshop. This approach results in in a more streamlined and effective learning process
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-img>
  </section>
</template>

<script>
export default {

}
</script>