<template>
  <section class="about">
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" xl="10">
          <div class="section-title text-center my-16">
            About MIS HOL
          </div>
          <v-row class="my-16">
            <v-col cols="12" lg="4" v-for="(item, i) in items" :key="i">
              <v-card class="mx-auto" max-width="300" style="border-radius: 0px">
                <v-card-text>
                  <div class="d-flex justify-center mt-3">
                    <div class="mr-4">
                      <v-avatar size="36" style="border: 2px solid #0F75BC">
                        <div class="body-1 primary-3--text font-weight-bold">
                          {{i+1}}  
                        </div> 
                      </v-avatar>
                    </div>
                    <div class="body-1 font-weight-bold black--text">
                      {{item.title}}
                    </div>
                  </div>
                  <div class="mt-5">
                    <div class=""  v-for="(text, j) in item.items" :key="j">
                      <div class="d-flex align-start black--text">
                        <v-icon color="#000">mdi-circle-medium</v-icon>
                        <div class="ml-2" style="margin-top: 2px">
                          {{text}}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    items: [
      {
        title: 'Complete Online Activity BEFORE the FTF Workshop',
        items: [
          'Pretest',
          'Lecture Videos',
          'Interim Test'
        ]
      },
      {
        title: 'Attend Live, Virtual FTF Workshop',
        items: [
          'Ask-the-Experts Session',
          'Hands-on Session for drills with laparoscopic instruments',
          'Open Forum with the faculty'
        ]
      },
      {
        title: 'Complete Online Activity AFTER the FTF Workshop',
        items: [
          'Posttest',
          'Evaluation',
          'Certification'
        ]
      }
    ]
  }),
}
</script>