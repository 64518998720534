<template>
  <v-app-bar 
  app
  hide-on-scroll
  flat
 
  color="transparent"
  height="100">
    <v-row justify="center" align="center">
      <v-col cols="12" xl="11">
        <v-toolbar class="nav" flat color="transparent">
          <v-toolbar-title class="mr-16">
            <v-img contain height="85" width="80" :src="require('../../assets/images/logo.png')"></v-img>
          </v-toolbar-title>
          <v-btn text v-for="(item, i) in links" :key="i"
          class="nav__link mx-5 hidden-sm-and-down" 
          :class="i==0 ? 'nav__link-active' : ''">
            {{item.text}}
          </v-btn>
          <v-spacer/>


          <v-btn large 
          class="nav__link primary--text"
          :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"
          style="border-radius: 0px; width: 200px">
            Sign up here
          </v-btn>

          <v-btn icon class="hidden-sm-and-up" @click="$emit('open')">
            <v-icon color="primary">
              mdi-menu
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        to: '',
        text: 'Home'
      },
      {
        to: '',
        text: 'About mis hol'
      },
      {
        to: '',
        text: 'mis hol steps'
      },
    ]
  }),
}
</script>
