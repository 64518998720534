<template>
  <section id="index">
    <v-app>
      <v-img contain 
      class="mb-10"
      :aspect-ratio="$vuetify.breakpoint.smAndUp ? 2 : ''"
      :src="$vuetify.breakpoint.smAndUp ? require('../../assets/images/banner.png')
      : require('../../assets/images/banner-mobile.png')"
      :style="$vuetify.breakpoint.xl ? 'margin-top: -20px'
      : $vuetify.breakpoint.smAndDownd ? 'width: 100%;' : ''">
        <Bar @open="dialog=true"/>
        <Hero/>
      </v-img>
      
      <Emercive/>
      <About/>
      <Steps/>

      <v-footer color="primary-3" height="50" class="mt-16"></v-footer>

      <v-dialog v-model="dialog" fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
        <v-app-bar flat color="#fff">
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog=false">
            <v-icon color="primary">
              mdi-close
            </v-icon>
          </v-btn>
        </v-app-bar>
        <v-card>
          <v-card-text class="text-center">
            <div class="d-flex flex-column pt-16">
              <v-btn text v-for="(item, i) in links" :key="i"
              class="nav__link my-5" 
              :class="i==0 ? 'nav__link-active' : ''">
                {{item.text}}
              </v-btn>
            </div>
            <div class=""></div>
            <v-btn large 
            class="nav__link primary--text mt-16"
            style="border-radius: 0px; width: 200px">
              Sign up here
            </v-btn>
          </v-card-text>
        </v-card>
      
      </v-dialog>
    </v-app>
  </section>
</template>

<script>
import Bar from '../components/Bar.vue'
import Hero from '../components/Hero.vue'
import Emercive from '../components/Emercive.vue'
import About from '../components/About.vue'
import Steps from '../components/Steps.vue'

export default {
  components: {
    Bar,
    Hero,
    Emercive,
    About,
    Steps
  },
  data: () => ({
    dialog: false,
    links: [
      {
        to: '',
        text: 'Home'
      },
      {
        to: '',
        text: 'About mis hol'
      },
      {
        to: '',
        text: 'mis Hol steps'
      },
    ]
  }),
};
</script>