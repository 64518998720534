<template>
  <section>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" xl="10">
          <div class="section-title text-center mt-16">
            MIS HOL STEPS
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="my-16">
        <v-col v-for="(item, i) in steps" 
        :key="i" 
        :cols="item.spacer ? 1 : 12" 
        :md="item.spacer ? '' : 3"
        :class="item.spacer ? 'text-center' : ''">
          <StepCard :item="item" :row="i" :count="3"/>
        </v-col>
      </v-row>
      <v-row v-row justify="center" align="center">
        <v-col cols="12" xl="10">
          <div class="section-title text-center mt-16">
            DURING THE WORKSHOP
          </div>
        </v-col>
      </v-row>  
      <v-row justify="center" align="center" class="my-16">
        <v-col cols="12" xl="4" lg="6" sm="10">
          <div v-for="(item, i) in workshops" :key="i" 
          class="d-flex align-center my-10">
            <div class="">
              <v-avatar color="primary-3">
                <div class="white--text font-weight-bold">
                  {{i==0?4:i==1?5:6}}
                </div>
              </v-avatar>
              <div class="caption">STEP {{i==0?4:i==1?5:6}}</div>
            </div>
            <v-sheet color="#F9F9F9" class="pa-5 ml-5" width="100%">
              {{item}}
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row v-row justify="center" align="center">
        <v-col cols="12" xl="10">
          <div class="section-title text-center mb-16">
            AFTER THE WORKSHOP
          </div>
        </v-col>
      </v-row>  
      <v-row justify="center" align="center">
        <v-col cols="12" xl="6" lg="8">
          <v-row justify="center" align="center" class="my-16">
            <v-col v-for="(item, i) in after" 
            :key="i" 
            :cols="item.spacer ? 1 : 12" 
            :md="item.spacer ? '' : 3"
            :class="item.spacer ? 'text-center' : ''">
            <StepCard :item="item" :row="i" :count="2"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import StepCard from './WorkshopCard.vue'

export default {
  components: {
    StepCard
  },
  data: () => ({
    steps: [
      {
        spacer: false,
        icon: 'mdi-check',
        text: 'Register for early access to the online course and create MDpie account'
      },
      {
        spacer: true,
        icon: 'mdi-arrow-right',
        text: ''
      },
      {
        spacer: false,
        icon: 'mdi-laptop',
        text: 'Access online'
      },
      {
        spacer: true,
        icon: 'mdi-arrow-right',
        text: ''
      },
      {
        spacer: false,
        icon: 'mdi-account-multiple-plus-outline',
        text: 'Register for the workshop'
      },
    ],
    workshops: [
      'Learn from the experts',
      'Interact during Ask-the-experts / open forum session',
      'Practice with the trainer box during hands-on activity'
    ],
    after: [
      {
        spacer: false,
        icon: 'mdi-playlist-check',
        text: 'Complete online activities'
      },
      {
        spacer: true,
        icon: 'mdi-arrow-right',
        text: ''
      },
      {
        spacer: false,
        icon: 'mdi-file-download-outline',
        text: 'Download Certificate of completion'
      },
    ]
  }),
}
</script>