<template>
  <v-container fill-height>
    <v-row align="center">
      <v-col cols="12" xl="8" lg="10" class="d-flex " :class="$vuetify.breakpoint.xl ? 'justify-center' : ''">
        <div class="hero " :class="$vuetify.breakpoint.mdAndUp ? '' : $vuetify.breakpoint.xs ? 'ml-2' : 'ml-10'">
          <div class="hero__title">
            Philippine Society of General 
          </div>
          <div class="hero__title" :style="$vuetify.breakpoint.xs ? '' : 'margin-top: -15px'">
            Surgeons Minimally 
          </div>
          <div class="hero__title" :style="$vuetify.breakpoint.xs ? '' : 'margin-top: -15px'">
            Invasive Surgery
          </div>
          <div class="hero__subtitle mx-1 mt-5" :class="$vuetify.breakpoint.mdAndUp ? 'mb-16' : 'mb-10'">
            Hybrid Online Learning
          </div>
          <v-btn 
          class="nav nav__link"
          x-large 
          style="width: 200px; border-radius: 0px" 
          color="primary-3" 
          to="/login"
          dark>
            Sign in
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>
