<template>
  <v-card v-if="!item.spacer" flat max-width="250" class="mx-auto">
    <div class="text-center">
      <div class="d-flex justify-center">
        <v-avatar tile size="50">
          <v-icon size="60">{{item.icon}}</v-icon>
        </v-avatar> 
      </div>
      <div class="my-5 font-weight-bold" v-if="count == 3">
        STEP {{row==0 ?1:row==2?2:row==4?3:'' }}
      </div>
      <div class="my-5 font-weight-bold" v-else>
        STEP {{row==0 ?7:8 }}
      </div>
      <div class="caption">
        {{item.text}}
      </div>
    </div>
  </v-card>
  <v-icon size="18" v-else class="hidden-sm-and-down">
    mdi-arrow-right
  </v-icon>
</template>

<script>
export default {
  props: ['item', "row", "count"]
}
</script>